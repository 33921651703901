<template>
  <div>
    <Multiselect
        v-model="districtIds"
        :options="districts"
        mode="tags"
        :close-on-select="false"
        :create-option="true"
        @select="updateSelectedDistricts"
        @deselect="updateSelectedDistricts"
    />
  </div>
</template>

<script>
import {ref, watch, onBeforeMount} from "vue";
import axios from "axios";
import {allDistrictsDisplay, getDistrictsByMultipleEsus} from "@/Helpers/ApiCalls/DistrictAPICalls";
import Multiselect from "@vueform/multiselect/src/Multiselect";
export default {
  name: "DistrictMultiSelect",
  components: {Multiselect},
  props: {
    esuIds: Array
  },
  setup(props, context) {
    const isLoaded = ref(false);
    const districts = ref([]);
    const districtIds = ref([]);

    onBeforeMount(() => {
      getDistrictHelper();
    })

    watch(() => props.esuIds, () => {
      getDistrictHelper();
    })

    function getDistrictHelper() {
      if (props.esuIds !== undefined && props.esuIds !== null && props.esuIds.length > 0) {
        getDistricts();
      } else {
        getDistrictsIfNoEsuSelected();
      }
    }

    async function getDistricts() {
      isLoaded.value = false;
      let params = new URLSearchParams();
      for (let i = 0; i < props.esuIds.length; i++) {
        params.append("esuId", props.esuIds[i]);
      }

      await axios.get(getDistrictsByMultipleEsus(), {
        params: params
      }).then((result) => {
        districts.value = result.data.map(d => ({
          label: d.districtName,
          value: d.idDistrict
        }))
      }).finally(() => {
        isLoaded.value = true;
      })
    }

    async function getDistrictsIfNoEsuSelected() {
      await axios.get(allDistrictsDisplay())
          .then((result) => {
        districts.value = result.data.map(d => ({
          label: d.districtName,
          value: d.idDistrict
        }))
      }).finally(() => {
        isLoaded.value = true;
      })
    }

    function updateSelectedDistricts() {
      context.emit("selectedDistricts", districtIds.value);
    }

    return {
      districts,
      districtIds,
      updateSelectedDistricts
    }
  }
}
</script>

<style scoped>

</style>