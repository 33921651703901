<template>
    <div class="row">
        <div class="col-sm">
            <label>Esu Multi Select</label>
            <EsuMultiSelect
                    @selectedEsus="updateEsuIds($event)"
            />
        </div>
        <div class="col-sm">
            <label>District Multi Select</label>
            <DistrictMultiSelect
                    :esuIds="esuIds"
                    @selectedDistricts="updateDistrictIds($event)"
            />
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-sm">
                <label>Retake Click</label>
                <br/>
                <button @click="showModal = true" type="submit" class="btn-primary button ">Show Retake Modal</button>

            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row">
            <button @click="showPwdModal = true" type="submit" class="btn-primary button">Show Password Modal</button>
        </div>
    </div>
    <div v-if="showModal">
        <Teleport to="body">
            <QuizRetakeModal
                    :course-id="261"
                    :quiz-id="564"
                    :user-canvas-id="1098"
                    :user-name="`Para Student 4`"
                    :quiz-title="`Test Quiz`"
                    :show="showModal"
                    @close="showModal = false"
                    @success="successValue($event)"/>
        </Teleport>
    </div>
    <div v-if="showPwdModal">
        <Teleport to="body">
            <PasswordResetComponent :user-email="`test@test.test`" :show="showPwdModal" @close="showPwdModal = false"
                                    :resetting-on-login="false"/>
        </Teleport>
    </div>
</template>

<script>
import {onBeforeMount, ref} from 'vue';
import EsuMultiSelect from "@/components/LocationSelect/EsuMultiSelect.vue";
import DistrictMultiSelect from "@/components/LocationSelect/DistrictMultiSelect.vue";
import QuizRetakeModal from "@/components/QuizRetake/QuizRetakeModal.vue";
import Swal from "sweetalert2";
import PasswordResetComponent from "@/components/PasswordReset/PasswordResetComponent.vue";
import {isSuperUser} from "@/RoleCheck";
import router from "@/router";
import store from "@/store";
import HistoricalCertificationTable from "@/components/HistoricalCertifications/HistoricalCertificationTable.vue";

export default {
	name: "ComponentExamples",
	// eslint-disable-next-line vue/no-unused-components
	components: {
      // eslint-disable-next-line vue/no-unused-components
		HistoricalCertificationTable,
		PasswordResetComponent, QuizRetakeModal, DistrictMultiSelect, EsuMultiSelect
	},
	setup() {
		const authUser = store.getters['auth/user'];
		const esuIds = ref([]);
		const distIds = ref([]);
		const showModal = ref(false);
		const showPwdModal = ref(false);

		onBeforeMount(() => {
			if (!isSuperUser(authUser.value.roleHierarchyId)) {
				router.replace({name: 'Home'});
			}
		})

		function updateEsuIds(event) {
			esuIds.value = event;
		}

		function updateDistrictIds(event) {
			distIds.value = event;
		}

		function successValue(event) {
			Swal.fire({
				title: "SUCCESS",
				text: `${event} extra attempt for user on this quiz`,
				icon: "success"
			})
		}

		return {
			esuIds,
			distIds,
			showModal,
			showPwdModal,
			updateEsuIds,
			updateDistrictIds,
			successValue
		}
	}
}
</script>

<style scoped>

</style>